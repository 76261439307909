

export default {
    name: 'Profile',
    components: {},
    asyncData({ api, cookies, ...context }) { },
    props: {},
    data() {
        return {
            skeleton: true,
            hasLogin: false,
            userInfo: {},
            bioShow: false,
            nickShow: false,
            sexPopup: false,
            countryPopup2: false,
            countryPopup: false,
            columns: [
                { text: this.$translate('Female'), value: 'F' },
                { text: this.$translate('Male'), value: 'M' },
            ],
            bio: '',
            nickName: '',
            currentSex: '',
            currentDate: new Date(),
            currentMonth: new Date(),
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.hasLogin = this.$login()
    },
    mounted() {
        this.initialSet()
    },
    methods: {
        birthdayConfirm(e) {
            let expYear = e.getFullYear();
            let expMonth = (e.getMonth() + 1).toString().padStart(2, '0');
            let expDay = e.getDate().toString().padStart(2, '0');
            this.userInfo.birthday = `${expYear}-${expMonth}-${expDay}`
            this.updateUser()
            this.countryPopup = false
        },
        marryConfirm(e) {
            let expMonth = (e.getMonth() + 1).toString().padStart(2, '0');
            let expDay = e.getDate().toString().padStart(2, '0');
            this.userInfo.marryMonth = expMonth
            this.userInfo.marryDay = expDay
            this.updateUser()
            this.countryPopup2 = false
        },
        nickConfirm(e) {
            if (this.nickName) {
                this.userInfo.nickName = this.nickName
                this.updateUser()
            }
        },
        bioConfirm() {
            if (this.bio) {
                this.userInfo.bio = this.bio
                this.updateUser()
            }
        },
        sexConfirm(e) {
            this.userInfo.sex = e.value
            this.updateUser()
            this.sexPopup = false
        },
        updateUser() {
            let data = {
                "id": this.userInfo.id,
                "headImage": this.userInfo.headImage,
                "nickName": this.userInfo.nickName,
                "birthday": this.userInfo.birthday,
                "bio": this.userInfo.bio,
                "sex": this.userInfo.sex,
                "language": this.userInfo.language,
                "marryMonth": this.userInfo.marryMonth,
                "marryDay": this.userInfo.marryDay
            }
            this.$api.user.setUserInfo(data).then(res => { })
        },
        afterRead(res) {
            this.userInfo.headImage = res.oss.reUrl
            this.updateUser()
        },
        // 邮箱
        handleEmail() {
            this.$Dialog.confirm({
                message: `${this.$translate('If you want to change email address, please contact us')}: yfn@service.com`,
                confirmButtonText: this.$translate('Copy Email'),
                cancelButtonText: this.$translate('Cancel'),
                cancelButtonColor: '#999999',
                confirmButtonColor: '#96BD2C'
            })
            .then(() => {
                this.$fnApi.copy.initial('yfn@service.com')
                this.$Toast(this.$translate('Copied'))
            })
            .catch(() => {});
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('My Profile'),
                keywords: this.$translate('My Profile'),
                description: this.$translate('My Profile'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        initialSet() {
            let clear = setTimeout(() => {
                this.skeleton = false
                clearTimeout(clear)
            }, 200)
            if (this.hasLogin) {
                let userId = this.$storage.get('userId');
                this.$api.user.getUseInfo({
                    id: userId
                }).then(response => {
                    const res = response.result || {};
                    this.userInfo = res || {}
                })
            }
        }
    },
}
